.call-to-action--wrapper {
  position: fixed;
  top: 18vh;
  left: 50%;
  transform: translate(-50%, 0);
  min-width: 42vw;
  max-width: var(--size--chat-history);
  height: calc(80vh - 85px);
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  flex-wrap: nowrap;
  overflow: visible;
  z-index: var(--z-index--ontop);

  h1 {
    color: var(--color--text--light);
  }

  button {
    background: var(--color--darkgray);
    background: var(--color--darkgray--gradient);
  }
}

.call-to-action--wrapper[data-visible=true] {
  opacity: 1;
  transform: translate(-50%, 0);
  transition: ease-out all var(--timing--hide-animation);
}

.call-to-action--wrapper[data-visible=false] {
  opacity: 0;
  transition: ease-in all var(--timing--hide-animation);
}

.call-to-action--wrapper[data-visible=false][data-hide-direction="top"] {
  transform: translate(-50%, -100vh);
}

.call-to-action--wrapper[data-visible=false][data-hide-direction="right"] {
  transform: translate(100vw, 0);
}

.call-to-action--wrapper[data-visible=false][data-hide-direction="bottom"] {
  transform: translate(-50%, 100vh);
}

.call-to-action--wrapper[data-visible=false][data-hide-direction="left"] {
  transform: translate(-100vw, 0);
}