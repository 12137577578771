.button--wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: var(--size-button--height);
  width: var(--size-button--width);
  border-radius: calc(var(--size-button--height)/2);
  padding: var(--padding-button--vertical) var(--padding-button--horizontal);
  background: var(--color--darkgray);
  border: 2px solid var(--color--darkgray);
  transition: ease-in-out all .24s, ease-in background 0s;
  
  svg {
    size: var(--size-button--icon-width);
    fill: var(--color--text--light);
    margin-left: var(--margin-icon--left);
    margin-right: var(--margin-icon--right);
  }
  
  .button--text {
    color: var(--color--text--light);
    align-self: center;
  }

  .button--inner {
    flex: 1;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  }
}

.button--wrapper:hover {
  background: var(--color--darkgray); 
  background: var(--color--darkgray--gradient);
  border: 2px solid var(--color--dark-700);
  transition: ease-in-out all .24s;
  transform: scale(1.02);
  
  .button--inner {
    transform: scale(0.96);
  }
}

/*
 * Sizing
 */
.button--wrapper[data-size="small"] .button--text {
  font-weight: var(--font-weight--button--small);
}

.button--wrapper[data-size="regular"] .button--text {
  font-weight: var(--font-weight--button--regular);
}

.button--wrapper[data-size="large"] .button--text {
  font-weight: var(--font-weight--button--large);
}

.button--wrapper[data-round=true] {
  width: var(--size-button--height);

  .button--text {
    display: none;
  }
}

/*
 * Colors
 */
.button--wrapper[data-color] {
  background: attr(data-color);
  border: 2px solid attr(data-color);
}

.button--wrapper[data-theme-color="green"] {
  background: var(--color--green);
  border: 2px solid var(--color--green);

  svg {
    fill: var(--color--text--light);
  }

  .button--text {
    color: var(--color--text--light);
  }
}

.button--wrapper[data-theme-color="green"]:hover {
  background: var(--color--green); 
  background: var(--color--green--gradient); 
}

.button--wrapper[data-theme-color="blue"] {
  background: var(--color--blue);
  border: 2px solid var(--color--blue);

  svg {
    fill: var(--color--text--light);
  }

  .button--text {
    color: var(--color--text--light);
  }
}

.button--wrapper[data-theme-color="blue"]:hover {
  background: var(--color--blue); 
  background: var(--color--blue--gradient); 
}

.chat-bubble--wrapper[data-speaker]:not([data-speaker="user"]) {
  .button--wrapper[data-theme-color="blue"] {
    background: var(--color--blue--gradient);
  }

  .button--wrapper[data-theme-color="green"] {
    background: var(--color--green--gradient);
  }
}