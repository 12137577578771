.head--wrapper {
  opacity: 0;
  z-index: var(--z-index--float);
  position: fixed;
  top: var(--head-position--top);
  right: var(--head-position--right);
  bottom: var(--head-position--bottom);
  left: var(--head-position--left);
  height: var(--head-size--height);
  width: var(--head-size--width);
  transform: translate(var(--head-transform--x) var(--head-transform--y));
  animation: none;
  transition: ease-in-out all .23s;
  
  .head--inner {
    transition: ease-out all .28s;
  }
}

.head--wrapper[data-bighead=true] .head--inner {
  transform: scale(1.38);
  transform-origin: center;
  transition: ease-in all .23s;
}

.head--wrapper.walking {
  animation: walking .46s infinite ease-in-out;
}

.head--wrapper.angry {
  animation: angry .26s infinite ease-in-out;
}

.head--wrapper[data-visible=false] {
  opacity: 0;
  transition: ease-in;
}

.head--wrapper[data-visible=true] {
  opacity: 1;
  transition: ease-out;
}

.head--pseudo-shadow {
  z-index: var(--z-index--below);
  display: block;
  bottom: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: var(--pseudo-shadow--width);
  height: calc(var(--pseudo-shadow--width)/4);
  background: var(--color--black);
  box-shadow: 0px -4px var(--box-shadow-spread--regular) var(--color--box-shadow--100);
  border-radius: var(--pseudo-shadow--border-radius);
}

.head--wrapper div[aria-label=animation] {
  margin: 0 !important;
}

@keyframes angry {
  from {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(4px, -13px);
  }
  66%{
    transform: translate(-6px, -8px);
  }
  to {
    transform: translate(0, 0);
  }
}

@keyframes walking {
  from {
    transform: translate(0, 0);
  }
  to {
    transform: translate(0, 0);
  }
}