@import-normalize;
/*@import url('./font-faces.css');*/
@import url('https://use.typekit.net/jgl7dfw.css');
@import url('./root.css');

body {
  margin: 0;
  font-size: 20px;
  font-family: var(--font-family--gill-sans--regular), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  color: var(--color--text);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

p {
  padding-top: 0.065rem
}

/* Hide scrollbar for Chrome, Safari and Opera */
/* Hide scrollbar for IE, Edge and Firefox */
* {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}
*::-webkit-scrollbar {
  display: none;
}

button,
optgroup,
select,
textarea,
html input[type="button"],
html input[disabled],
input[type="reset"],
input[type="submit"],
input,
input[type="checkbox"],
input[type="radio"],
input[type="search"],
button[disabled] {
  border: none;
  padding: 0;
  margin: 0;
  background-image: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  font-size: 20px;
  font-family: var(--font-family--gill-sans--regular), -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

input:focus,
input:focus-visible {
  outline: none;
}

h1 {
  font-size: var(--font-size--h1);
}
h2 {
  font-size: var(--font-size--h2);
}
h3 {
  font-size: var(--font-size--h3);
}
h4 {
  font-size: var(--font-size--h4);
}
h5 {
  font-size: var(--font-size--h5);
}
h6 {
  font-size: var(--font-size--h6);
}