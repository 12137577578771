.background--wave {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 300%;
  width: 100%;
  background-image: url(../../assets/images/background--wave.jpg);
  background-size: 100% 100%;
  background-position: top center;
  transform: translateY(0);
  animation: wave 420s linear infinite;
}

@keyframes wave {
  0% {
    transform: translateY(0);
  }
  100% {
    transform: translateY(-66.666%);
  }
}