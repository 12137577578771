:root {
  --input--wrapper--height: 86px;
  --input--inner-button--width: 54px;
  --input--inner-button--height: var(--input--inner-button--width);
  --input--inner-button--spacing: calc((var(--input--wrapper--height) - var(--input--inner-button--width))/2);
  --input--inner--padding-right: calc(var(--spacing--m) + var(--input--inner-button--width)*2 + var(--spacing--s));
}

.input--wrapper {
  position: fixed;
  display: flex;
  top: calc(86vh - var(--input--wrapper--height));
  height: var(--input--wrapper--height);
  width: 100vw;
  pointer-events: none;
  justify-content: center;
  transition: ease-in top 0.26s;
  border: 1px solid transparent;
  box-sizing: border-box;
  z-index: calc(var(--z-index--ontop) + 1);
  
  .input--inner {
    position: relative;
    overflow: hidden;
    pointer-events: auto;
    width: max(calc(100vw - var(--spacing--l)*2), 676px);
    max-width: 716px;
    height: 100%;
    margin-left: var(--spacing--l);
    margin-right: var(--spacing--l);
    background: var(--color--gray-300);
    border-radius: var(--border-radius--user-input);
    box-shadow: 0px -8px var(--box-shadow-spread--medium) var(--color--box-shadow--10), 0px -1px var(--box-shadow-spread--tiny) var(--color--box-shadow--10);
    border: 1px solid var(--color--gray-200);
    
    input.input--user-input {
      margin: 2px;
      width: calc(100% - 4px);
      height: calc(100% - 4px);
      border-radius: calc(var(--border-radius--user-input) - 2px);
      padding: var(--spacing--m) var(--input--inner--padding-right) var(--spacing--m) var(--spacing--l);
      box-sizing: border-box;
    }

    input.input--user-input:focus, 
    input.input--user-input:focus-visible {
      border: 1px solid var(--color--focus);
    }
  }

  .input--mic,
  .input--submit {
    display: flex;
    align-items: center;
    justify-content: center;
    width: var(--input--inner-button--width);
    height: var(--input--inner-button--height);
    border-radius: 32px;
    position: absolute;
    color: var(--color--black);
    z-index: var(--z-index--float);
    top: var(--input--inner-button--spacing);
    right: var(--input--inner-button--spacing);
  }

  .input--mic {
    right: calc(var(--input--inner-button--spacing) + var(--input--inner-button--width) + var(--spacing--s));
  }
}

.input--wrapper.hidden {
  top: calc(100vh + 86px);
  transition: ease-out top 0.26s;
}

.input--wrapper.prompt .input--inner {
  animation: loud 0.2s forwards;
}

@keyframes wiggle {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  25% {
    transform: translate(4px, 5px) rotate(2deg);
  }
  50% {
    transform: translate(0, 0) rotate(0eg);
  }
  75% {
    transform: translate(-5px, 6px) rotate(-3deg);
  }
  100% {
    transform: translate(0, 0) rotate(0deg);
  }
}